<template>
  <div :class="platform" class="block-item">
    <dt>
      <slot name="termin">{{ props.termin }}</slot>
    </dt>
    <dd>
      <slot>{{ props.description }}</slot>
    </dd>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  termin?: string
  description?: string
}>()

const { platform } = usePlatform()
</script>

<style scoped>
.block-item {
  overflow: hidden;
  display: flex;
  gap: var(--spacing-200);
  width: 100%;

  &:not(:last-child) {
    padding-bottom: var(--spacing-150);
    border-bottom: var(--border-width-light) solid var(--border-primary);
  }

  dt {
    margin: 0;
    font: var(--desktop-text-sm-medium);
    color: var(--text-tertiary);
  }

  dd {
    min-width: 100px;
    margin-left: auto;

    font: var(--desktop-text-sm-semibold);
    color: var(--text-primary);
    text-align: right;

    &.column {
      display: flex;
      flex-direction: column;
    }
  }

  &.mobile {
    &:not(:last-child) {
      padding-bottom: var(--spacing-125);
    }

    dt {
      font: var(--mobile-text-content-regular);
    }

    dd {
      /* stylelint-disable */
      font-family: 'SF Pro Display';
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      line-height: 20px;
      /* stylelint-enable */
    }
  }
}
</style>
